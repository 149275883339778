import { SxProps, Theme } from '@mui/material/styles';
import { DEFAULT_TABLE_AUTOCOMPLETE_WIDTH } from 'src/pages/backoffice/consts';

export default (): Record<string, SxProps<Theme>> => ({
  checkboxCell: {
    padding: '10px 0 0 !important',
    textAlign: 'center',
    verticalAlign: 'top',
  },
  autocompleteCell: {
    maxWidth: DEFAULT_TABLE_AUTOCOMPLETE_WIDTH,
  },
});
