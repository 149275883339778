import { MySkyLoader } from '@core-ui/icons';
import { HEIGHT_SIZE } from '@core-ui/styles';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import { SxProps, Theme } from '@mui/material/styles';
import { router } from 'src/configureStore';
import BackofficeTabs from 'src/pages/backoffice/BackofficeTabs';
import BackofficeModals from 'src/pages/backoffice/Modals';
import { BACKOFFICE_ROUTE } from 'src/pages/backoffice/routes';
import React, { CSSProperties } from 'react';

const containerStyle: SxProps<Theme> = {
  padding: `${HEIGHT_SIZE.tabsHeight}px 0 0 0 !important`,
};

const fallbackStyle: CSSProperties = {
  margin: 'auto',
};

const BackofficeRoot = () => {
  return (
    <>
      <BackofficeTabs />

      <Container maxWidth="xl" sx={containerStyle}>
        <Grid item>
          {BACKOFFICE_ROUTE.routes && router.render(BACKOFFICE_ROUTE.routes, <MySkyLoader style={fallbackStyle} />)}
        </Grid>
      </Container>

      <BackofficeModals />
    </>
  );
};

export default BackofficeRoot;
