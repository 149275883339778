import SvgIcon from '@mui/material/SvgIcon';
import Tooltip from '@mui/material/Tooltip';
import { OrderContractContentFields } from 'src/generated';
import { DEFAULT_TABLE_AUTOCOMPLETE_WIDTH } from 'src/pages/backoffice/consts';
import { ITableColumn } from 'src/pages/backoffice/types';
import { Envelope } from '@phosphor-icons/react';
import React from 'react';
import { FormattedMessage } from 'react-intl';

// TODO: отсутствует колонка Tags т.к. её реализация отложена и на фронте и на бэке

export const HEADERS: ITableColumn[] = [
  {
    key: 'document_name',
    title: <FormattedMessage id="table.header.document_name" />,
    orderBy: OrderContractContentFields.DOC_NAME,
    dataTestId: 'table-column-head-document-name',
    width: 218,
  },
  {
    key: 'vendor',
    title: <FormattedMessage id="table.header.vendor" />,
    orderBy: OrderContractContentFields.VENDOR_NAME,
    dataTestId: 'table-column-head-vendor',
    width: 228,
  },
  {
    key: 'upload_date',
    title: <FormattedMessage id="table.header.upload_date" />,
    orderBy: OrderContractContentFields.CREATED_AT,
    dataTestId: 'table-column-head-created-at',
    width: 140,
  },
  {
    key: 'document_status',
    title: <FormattedMessage id="table.header.doc_status" />,
    orderBy: OrderContractContentFields.DOC_STATUS,
    dataTestId: 'table-column-head-document-status',
    width: 228,
  },
  {
    key: 'erp_category',
    title: <FormattedMessage id="table.header.erp_category" />,
    orderBy: OrderContractContentFields.ERP_CATEGORY,
    dataTestId: 'table-column-head-erp-category',
    width: DEFAULT_TABLE_AUTOCOMPLETE_WIDTH,
  },
  {
    key: 'payment_date',
    title: <FormattedMessage id="table.header.payment_date" />,
    orderBy: OrderContractContentFields.PAYMENT_DATE,
    dataTestId: 'table-column-head-payment-date',
    width: 140,
  },
  {
    key: 'uploaded_by',
    title: <FormattedMessage id="label.uploaded_by" />,
    orderBy: OrderContractContentFields.UPLOADED_BY_ID,
    dataTestId: 'table-column-head-uploaded-by',
    width: 200,
  },
  {
    key: 'erp_status_icon',
    title: (
      <Tooltip title={<FormattedMessage id="label.erp_status" />} placement="top" arrow>
        <SvgIcon>
          <Envelope />
        </SvgIcon>
      </Tooltip>
    ),
    align: 'center',
    width: 48,
    right: 252,
    shadow: 'dynamicLeft',
  },
  {
    key: 'expenses',
    title: <FormattedMessage id="table.header.expenses" />,
    align: 'right',
    width: 144,
    right: 108,
  },
  {
    title: '',
    key: 'actions',
    width: 108,
    right: 0,
  },
];
