import Immutable from '@core-ui/immutable';
import reducer from '@core-ui/reducers_handlers';
import { IOption, Nullable } from '@core-ui/types';
import { handleActions } from 'redux-actions';
import * as actions from './actions';

export interface IContractsFiltersState {
  name: string;
  vendorsId: Nullable<IOption<string, string>[]>;
}

const defaultState = Immutable<IContractsFiltersState>({
  name: '',
  vendorsId: null,
});

export default handleActions({}, reducer<typeof defaultState>(actions.reducerName, defaultState));
