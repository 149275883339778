import Autocomplete from '@core-ui/autocomplete';
import Button from '@core-ui/button';
import { useFilter } from '@core-ui/hooks';
import TextField from '@core-ui/text_field';
import Grid from '@mui/material/Grid';
import SvgIcon from '@mui/material/SvgIcon';
import { useAppSelector } from 'src/hooks/useAppSelector';
import { IContractsFiltersState } from 'src/pages/backoffice/Contracts/ContractsFilters/reducer';
import { getContractsList, setContractListLoading } from 'src/pages/backoffice/Contracts/ContractsList/actions';
import { openBackofficeModal } from 'src/pages/backoffice/Modals/actions';
import { CreateEditContractModalData } from 'src/pages/backoffice/Modals/command';
import { MagnifyingGlass, PlusCircle } from '@phosphor-icons/react';
import React, { FC, useCallback } from 'react';
import { FormattedMessage } from 'react-intl';
import { useDispatch } from 'react-redux';
import { setTextFilter } from './actions';
import selector from './selector';

const ContractsFilters: FC = () => {
  const { filters, vendorsOptions } = useAppSelector(selector);
  const dispatch = useDispatch();

  const setLoadingCallback = useCallback(
    () => dispatch(setContractListLoading({ loading: true })),
    [setContractListLoading]
  );

  const handleFilterChange = useFilter<IContractsFiltersState>({
    getList: getContractsList,
    setFilter: setTextFilter,
    onChange: setLoadingCallback,
  });

  const handleCreateNewContractModalOpen = () => {
    dispatch(openBackofficeModal(new CreateEditContractModalData(null, true)));
  };

  return (
    <Grid item container gap={3} paddingY={6}>
      <Grid item width={326}>
        <TextField
          fullWidth
          size="medium"
          label={<FormattedMessage id="filters.search" />}
          // TODO: тип параметра onChange в компоненте TextField не соответствует handleFilterChange
          // @ts-ignore
          onChange={handleFilterChange('name')}
          value={filters.name}
          rightIcon1={
            <SvgIcon>
              <MagnifyingGlass />
            </SvgIcon>
          }
        />
      </Grid>

      <Grid item width={270}>
        <Autocomplete
          fullWidth
          multiple
          limitTags={2}
          label={<FormattedMessage id="label.vendor" />}
          value={filters.vendorsId}
          options={vendorsOptions}
          onChange={handleFilterChange('vendorsId')}
        />
      </Grid>

      <Grid item marginLeft="auto">
        <Button
          variant="contained"
          color="blue"
          size="large"
          startIcon={<PlusCircle />}
          onClick={handleCreateNewContractModalOpen}
          label={<FormattedMessage id="label.add_new_contract" />}
        />
      </Grid>
    </Grid>
  );
};

export default ContractsFilters;
