import { createParseQuery, resetState, setFieldFilter } from '@core-ui/reducers_handlers';
import { createAction } from 'redux-actions';

export const reducerName = 'contracts.singleContract.filters';

export const setTextFilter = setFieldFilter(reducerName);
export const resetSingleContractTransactionsFilters = resetState(reducerName);

export const updateSingleContractTransactionsFiltersQuery = createAction(
  `${reducerName}.UPDATE_SINGLE_CONTRACT_TRANSACTIONS_FILTERS_QUERY`
);
export const setSingleContractTransactionsFiltersFromQuery = createParseQuery(reducerName);
