import contractsFiltersSagas from './ContractsFilters/sagas';
import contractsListSagas from './ContractsList/sagas';
import singleContractRootSagas from './SingleContractRoot/sagas';
import singleContractTransactionsSagas from './SingleContractTransactions/sagas';

export default [
  ...contractsFiltersSagas,
  ...contractsListSagas,
  ...singleContractRootSagas,
  ...singleContractTransactionsSagas,
];
