import { COLOURS } from '@core-ui/styles';
import { IMenuOption } from 'src/components/Menu/Menu';
import MoreButton from 'src/components/MoreButton';
import { ContractOutputSchema } from 'src/generated';
import { openBackofficeModal } from 'src/pages/backoffice/Modals/actions';
import { CreateEditContractModalData, DeleteContractModalData } from 'src/pages/backoffice/Modals/command';
import { NotePencil, Trash } from '@phosphor-icons/react';
import React, { FC } from 'react';
import { FormattedMessage } from 'react-intl';
import { useDispatch } from 'react-redux';

interface IProps {
  item: ContractOutputSchema;
}

const ContractsListRowMoreButton: FC<IProps> = (props) => {
  const { item } = props;

  const dispatch = useDispatch();

  const handleEdit = () => {
    dispatch(openBackofficeModal(new CreateEditContractModalData(item, false)));
  };

  const handleDelete = () => {
    dispatch(openBackofficeModal(new DeleteContractModalData(item.id, false)));
  };

  const options: IMenuOption[] = [
    {
      title: <FormattedMessage id="label.edit" />,
      icon: <NotePencil />,
      onClick: handleEdit,
      visible: true,
      dataTestId: 'contract-row-more-button-edit-option',
    },
    {
      title: <FormattedMessage id="label.delete" />,
      textColor: COLOURS.HUE_STRAWBERRY.HUE_100,
      icon: <Trash color={COLOURS.HUE_STRAWBERRY.HUE_100} />,
      onClick: handleDelete,
      visible: true,
      dataTestId: 'contract-row-more-button-delete-option',
    },
  ];

  return <MoreButton options={options} dataTestId="contract-row-more-button-menu" />;
};

export default ContractsListRowMoreButton;
