import { DEBOUNCE_PERIOD } from '@core-ui/consts';
import { updateQuery } from '@core-ui/url';
import { ISagaContext } from 'src/app/types/common';
import { IInboxFiltersState } from 'src/pages/backoffice/Inbox/InboxFilters/reducer';
import { debounce, getContext, select, takeLatest } from 'redux-saga/effects';
import * as actions from './actions';
import { getInboxFilters } from './selector';

function* filterToQuerySaga() {
  const filters: IInboxFiltersState = yield select(getInboxFilters);
  const history: ISagaContext['history'] = yield getContext('history');

  history.replace(updateQuery(filters));
}

export default [
  debounce(DEBOUNCE_PERIOD, actions.setTextFilter, filterToQuerySaga),
  takeLatest(actions.updateInboxFiltersQuery, filterToQuerySaga),
];
