import { extractFlattenPaths, Route } from '@core-ui/redux-router';
import { ROUTES } from 'src/app/consts/routes';
import { getClientCostArticleDict } from 'src/dictionary/actions';
import { BACKOFFICE_TABS } from 'src/pages/backoffice/consts';
import {
  resetInboxFilters,
  setInboxFiltersFromQuery,
  updateInboxFiltersQuery,
} from 'src/pages/backoffice/Inbox/InboxFilters/actions';
import { getInboxList, resetInboxList } from 'src/pages/backoffice/Inbox/InboxList/actions';
import InboxRoot from 'src/pages/backoffice/Inbox/InboxRoot';
import { IBackofficeState } from 'src/pages/backoffice/reducer';

const key: keyof IBackofficeState = 'inbox';

const routes: Route[] = [
  {
    key,
    exact: true,
    path: `/:boatId([0-9]+)/:route(${ROUTES.BACKOFFICE})/:backofficeTab(${BACKOFFICE_TABS.INBOX})`,
    component: InboxRoot,
    effects: [
      { events: ['on_enter'], trigger: resetInboxList },
      { events: ['on_enter'], trigger: resetInboxFilters },
      { events: ['on_enter'], trigger: setInboxFiltersFromQuery },
      { events: ['on_enter'], trigger: updateInboxFiltersQuery },
      { events: ['on_enter'], trigger: getInboxList },
      { events: ['once'], trigger: getClientCostArticleDict },
    ],
  },
];

export const INBOX_PATHS = extractFlattenPaths(routes);

export default routes;
