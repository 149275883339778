import { vehicleIdSelector } from 'src/app/selectors';
import State from 'src/app/types/state';
import { getClientCostArticlesDictOptions } from 'src/dictionary/selector';
import { getCheckedItems } from 'src/pages/backoffice/Contracts/SingleContractTransactions/SingleContractTransactionsList/selector';
import { createSelector } from 'reselect';

const getCheckedItemId = (_: State, props: { id: number }) => props.id;

const getItemCheckedById = createSelector([getCheckedItems, getCheckedItemId], (checkedItems, id) => {
  return id && checkedItems[id]?.checked;
});

export default () =>
  createSelector(
    [vehicleIdSelector, getClientCostArticlesDictOptions, getItemCheckedById],
    (boatId, clientCostArticlesOptions, checked) => ({
      boatId,
      clientCostArticlesOptions,
      checked: Boolean(checked),
    })
  );
