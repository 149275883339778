import Immutable from '@core-ui/immutable';
import reducer from '@core-ui/reducers_handlers';
import { IOption, Nullable } from '@core-ui/types';
import { getEndOfTodayInUnix, getStartOfCurrentYearInUnix } from 'src/app/utils/dates';
import { FileStatusEnum } from 'src/generated';
import { handleActions } from 'redux-actions';
import * as actions from './actions';

export interface ISingleContractListFiltersState {
  search: string;
  dateFrom: Nullable<string>;
  dateTo: Nullable<string>;
  uploadedBy: Nullable<IOption<string, string>[]>;
  status: Nullable<IOption<FileStatusEnum, FileStatusEnum>[]>;
}

const defaultState = Immutable<ISingleContractListFiltersState>({
  search: '',
  dateFrom: String(getStartOfCurrentYearInUnix()),
  dateTo: String(getEndOfTodayInUnix()),
  uploadedBy: null,
  status: null,
});

export default handleActions({}, reducer<typeof defaultState>(actions.reducerName, defaultState));
