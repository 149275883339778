import { FileStatusEnum } from 'src/generated';
import { createAction } from 'redux-actions';

export const reducerName = 'inbox.list.row';

export interface IDeleteInboxDocument {
  id: number;
  status: FileStatusEnum;
}

export const deleteInboxDocument = createAction<IDeleteInboxDocument>(`${reducerName}.DELETE_INBOX_DOCUMENT`);
