import { getAmountFormatter, getCurrencyCodeById } from 'src/dictionary/selector';
import { getSingleContract } from 'src/pages/backoffice/Contracts/SingleContractRoot/selector';
import { createSelector } from 'reselect';

export default createSelector(
  [getSingleContract, getAmountFormatter, getCurrencyCodeById],
  (transaction, amountFormatter, getCurrencyCode) => ({
    item: transaction.value,
    amountFormatter,
    getCurrencyCode,
  })
);
