import { useQuery } from '@core-ui/redux-router';
import { HEIGHT_SIZE } from '@core-ui/styles';
import { updateQuery } from '@core-ui/url';
import Container from '@mui/material/Container';
import { SxProps, Theme } from '@mui/material/styles';
import { SINGLE_CONTRACT_TAB_ENUM } from 'src/pages/backoffice/Contracts/consts';
import SingleContractHeader from 'src/pages/backoffice/Contracts/SingleContractHeader';
import SingleContractInfo from 'src/pages/backoffice/Contracts/SingleContractInfo';
import SingleContractTransactions from 'src/pages/backoffice/Contracts/SingleContractTransactions';
import { SingleContractQueryParams } from 'src/pages/backoffice/Contracts/types';
import React, { useEffect } from 'react';

const containerStyle: SxProps<Theme> = {
  padding: '0 24px !important',
  height: `calc(100vh - (${HEIGHT_SIZE.headerHeight + HEIGHT_SIZE.tabsHeight}px))`,
  overflow: 'hidden',
};

const SingleContractRoot = () => {
  const { tab } = useQuery<SingleContractQueryParams>();

  useEffect(() => {
    if (!tab) {
      updateQuery<SingleContractQueryParams>({
        tab: SINGLE_CONTRACT_TAB_ENUM.INFORMATION,
      });
    }
  }, []);

  let content: JSX.Element;

  switch (tab) {
    case SINGLE_CONTRACT_TAB_ENUM.INFORMATION:
      content = <SingleContractInfo />;
      break;
    case SINGLE_CONTRACT_TAB_ENUM.TRANSACTIONS:
      content = <SingleContractTransactions />;
      break;
    default:
      content = <SingleContractInfo />;
  }

  return (
    <Container maxWidth="xl" sx={containerStyle}>
      <SingleContractHeader />

      {content}
    </Container>
  );
};

export default SingleContractRoot;
