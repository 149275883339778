import { getParams } from '@core-ui/redux-router';
import { POST } from 'src/api/oceanApi';
import { responseError } from 'src/app/sagas';
import { ISagaContext } from 'src/app/types/common';
import { showNotification } from 'src/components/Notification/actions';
import { CONTRACTS_PATHS } from 'src/pages/backoffice/Contracts/routes';
import { getSingleContract } from 'src/pages/backoffice/Contracts/SingleContractRoot/actions';
import { getSingleContractTransactions } from 'src/pages/backoffice/Contracts/SingleContractTransactions/SingleContractTransactionsList/actions';
import { SingleContractPathParams } from 'src/pages/backoffice/Contracts/types';
import { Action } from 'redux-actions';
import { all, call, getContext, put, takeEvery } from 'redux-saga/effects';
import * as actions from './actions';

const createFormData = (payload: actions.IUploadDocument, contractId: string) => {
  const formData = new FormData();

  formData.append('contract_id', contractId);

  payload.files.forEach((file) => {
    formData.append('fileobjects', file);
  });

  return formData;
};

function* uploadDocument({ payload }: Action<actions.IUploadDocument>) {
  const history: ISagaContext['history'] = yield getContext('history');

  try {
    const params = getParams<SingleContractPathParams>(history.location.pathname, CONTRACTS_PATHS);
    const fileNames = payload.files.map((file) => file.name);

    yield all([put(actions.setLoading(true)), put(actions.setPlaceholders(fileNames))]);

    const formData = createFormData(payload, params.contractId);

    yield call(POST, '/files', formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });

    yield all([
      put(getSingleContract(null)),
      put(getSingleContractTransactions(null)),
      put(
        showNotification({
          variant: 'success',
          titleId: 'notification.success.text.document_uploaded',
          message: '',
        })
      ),
    ]);
  } catch (e) {
    yield call(responseError, e);
  } finally {
    yield all([put(actions.setLoading(false)), put(actions.setPlaceholders([]))]);
  }
}

export default [takeEvery(actions.uploadDocument, uploadDocument)];
