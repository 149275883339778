import State from 'src/app/types/state';
import { NoDataContainerSelector } from 'src/components/NoDataContainer/NoDataContainer';
import { getAmountFormatter } from 'src/dictionary/selector';
import { FileStatusEnum } from 'src/generated';
import { getSingleContractTransactionsFilters } from 'src/pages/backoffice/Contracts/SingleContractTransactions/SingleContractTransactionsFilters/selector';
import { createSelector } from 'reselect';

const getChunkLoading = (state: State) => state.backoffice.contracts.singleContractTransactions.list.chunkLoading;
const getAllDataReceived = (state: State) =>
  state.backoffice.contracts.singleContractTransactions.list.isAllDataReceived;

export const chunkLoaderSelector = createSelector(
  [getChunkLoading, getAllDataReceived],
  (isChunkLoading, isAllDataReceived) => ({
    isChunkLoading,
    isAllDataReceived,
  })
);

export const getSingleContractTransactionsList = (state: State) =>
  state.backoffice.contracts.singleContractTransactions.list;

export const getNoDataContainerData: NoDataContainerSelector = createSelector(
  [getSingleContractTransactionsList],
  (state) => ({
    loading: state.loading,
    loaded: state.loaded,
    hasData: state.hasData,
    error: state.error,
  })
);

const getOrderBy = (state: State) => state.backoffice.contracts.singleContractTransactions.list.orderBy;
const getSortDesc = (state: State) => state.backoffice.contracts.singleContractTransactions.list.isSortDesc;
export const getCheckedItems = (state: State) =>
  state.backoffice.contracts.singleContractTransactions.list.checkedItems;
export const getSingleContractTransactionsItems = (state: State) =>
  state.backoffice.contracts.singleContractTransactions.list.value;

export const getSortOrderChecked = createSelector(
  [getOrderBy, getSortDesc, getCheckedItems, getSingleContractTransactionsItems],
  (orderBy, isSortDesc, checkedItems, singleContractTransactionItems) => {
    let checked = false;
    let indeterminate = false;

    const values = Object.values(checkedItems);

    const isAllItemsChecked = values.length > 0 && values.every((item) => item.checked);
    const isSomeItemsChecked = values.length > 0 && values.some((item) => item.checked);
    const isAllItemsDisabled = Boolean(
      singleContractTransactionItems?.every((item) => item.status !== FileStatusEnum.DIGITIZED)
    );

    if (isAllItemsChecked) {
      checked = true;
      indeterminate = false;
    }

    if (!isAllItemsChecked && isSomeItemsChecked) {
      checked = true;
      indeterminate = true;
    }

    return {
      orderBy,
      isSortDesc,
      checked,
      indeterminate,
      isAllItemsDisabled,
    };
  }
);

export const getPaginationPage = (state: State) =>
  state.backoffice.contracts.singleContractTransactions.list.paginationPage;

const getPlaceholders = (state: State) => state.backoffice.contracts.singleContractTransactions.dragNDrop.placeholders;

const getFiltersApplied = createSelector([getSingleContractTransactionsFilters], (filters) =>
  Object.values(filters).some((value) => {
    if (Array.isArray(value)) {
      return value.length > 0;
    }

    return Boolean(value);
  })
);

export default createSelector(
  [getPlaceholders, getSingleContractTransactionsItems, getAmountFormatter, getFiltersApplied],
  (placeholders, singleContractTransactionsItems, amountFormatter, isFiltersApplied) => ({
    placeholders,
    items: singleContractTransactionsItems,
    amountFormatter,
    isFiltersApplied,
  })
);
