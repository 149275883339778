import { COLOURS } from '@core-ui/styles';
import { SxProps, Theme } from '@mui/material/styles';

export default (): Record<string, SxProps<Theme>> => ({
  dropzone: {
    border: `1px solid ${COLOURS.HUE_COCONUT.HUE_32}`,
    background: COLOURS.HUE_COCONUT.HUE_24,
    borderRadius: 1,
    cursor: 'pointer',
    height: 118,
  },
});
