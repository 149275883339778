import State from 'src/app/types/state';
import { NoDataContainerSelector } from 'src/components/NoDataContainer/NoDataContainer';
import { getAmountFormatter, getCurrencyCodeById } from 'src/dictionary/selector';
import { getContractsFilters } from 'src/pages/backoffice/Contracts/ContractsFilters/selector';
import { createSelector } from 'reselect';

export const getContractList = (state: State) => state.backoffice.contracts.list;

export const getNoDataContainerData: NoDataContainerSelector = createSelector(
  [getContractList],
  (contractsListState) => ({
    loading: contractsListState.loading,
    loaded: contractsListState.loaded,
    hasData: contractsListState.hasData,
    error: contractsListState.error,
  })
);

const getChunkLoading = (state: State) => state.backoffice.contracts.list.chunkLoading;
const getAllDataReceived = (state: State) => state.backoffice.contracts.list.isAllDataReceived;

export const chunkLoaderSelector = createSelector(
  [getChunkLoading, getAllDataReceived],
  (isChunkLoading, isAllDataReceived) => ({
    isChunkLoading,
    isAllDataReceived,
  })
);

export const getPaginationPage = (state: State) => state.backoffice.contracts.list.paginationPage;

export const getNoContracts: NoDataContainerSelector = createSelector(
  [getContractList, getContractsFilters],
  (contractsListState, filters) => ({
    loading: contractsListState.loading,
    loaded: contractsListState.loaded,
    hasData: Boolean(contractsListState.hasData || filters.name || filters.vendorsId),
    error: contractsListState.error,
  })
);

const getOrderBy = (state: State) => state.backoffice.contracts.list.orderBy;
const getSortDesc = (state: State) => state.backoffice.contracts.list.isSortDesc;

export const getSortAndOrder = createSelector([getOrderBy, getSortDesc], (orderBy, isSortDesc) => ({
  orderBy,
  isSortDesc,
}));

const getFiltersApplied = createSelector([getContractsFilters], (filters) =>
  Object.values(filters).some((value) => {
    if (Array.isArray(value)) {
      return value.length > 0;
    }

    return Boolean(value);
  })
);

export default createSelector(
  [getContractList, getAmountFormatter, getCurrencyCodeById, getFiltersApplied],
  (contractsListState, amountFormatter, getCurrencyCode, isFiltersApplied) => ({
    items: contractsListState.value,
    amountFormatter,
    getCurrencyCode,
    isFiltersApplied,
  })
);
