import { SxProps, Theme } from '@mui/material/styles';

export default (): Record<string, SxProps<Theme>> => ({
  nameCell: {
    wordBreak: 'break-all',
    cursor: 'pointer',
  },

  cell: {
    wordBreak: 'break-all',
  },
});
