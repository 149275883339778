import { getParams } from '@core-ui/redux-router';
import { Nullable } from '@core-ui/types';
import { buildURL } from '@core-ui/url';
import { GET } from 'src/api/oceanApi';
import { responseError } from 'src/app/sagas';
import { ISagaContext } from 'src/app/types/common';
import { ContractContentSchema, FileStatusEnum, InboxRecordSchema, OrderContractContentFields } from 'src/generated';
import { CONTRACTS_PATHS } from 'src/pages/backoffice/Contracts/routes';
import { ISingleContractListFiltersState } from 'src/pages/backoffice/Contracts/SingleContractTransactions/SingleContractTransactionsFilters/reducer';
import { getSingleContractTransactionsFilters } from 'src/pages/backoffice/Contracts/SingleContractTransactions/SingleContractTransactionsFilters/selector';
import {
  getPaginationPage,
  getSingleContractTransactionsItems,
  getSortOrderChecked,
} from 'src/pages/backoffice/Contracts/SingleContractTransactions/SingleContractTransactionsList/selector';
import { ICheckedItems, SingleContractPathParams } from 'src/pages/backoffice/Contracts/types';
import { all, call, getContext, put, select, takeLatest } from 'redux-saga/effects';
import * as actions from './actions';

const LIMIT = 50;

interface SortAndOrderDesc {
  orderBy: Nullable<OrderContractContentFields>;
  isSortDesc: Nullable<boolean>;
}

interface IFetchContractContent {
  page: number;
}

function* fetchContractContent({ page }: IFetchContractContent) {
  const history: ISagaContext['history'] = yield getContext('history');
  const params = getParams<SingleContractPathParams>(history.location.pathname, CONTRACTS_PATHS);

  const filters: ISingleContractListFiltersState = yield select(getSingleContractTransactionsFilters);

  const { orderBy, isSortDesc }: SortAndOrderDesc = yield select(getSortOrderChecked);

  const requestUrl = buildURL(
    `/contracts/${params.contractId}/list`,
    {
      page,
      per_page: LIMIT,
      order_by: orderBy || undefined,
      is_desc: Boolean(isSortDesc),
      search: filters.search || undefined,
      date_from: filters.dateFrom ? Number(filters.dateFrom) : undefined,
      date_to: filters.dateTo ? Number(filters.dateTo) : undefined,
      uploaded_by: filters.uploadedBy || undefined,
      filter_statuses: filters.status || undefined,
    },
    {
      arrayFormat: 'none',
    }
  );

  const contractContent: ContractContentSchema = yield call(GET, requestUrl);

  return contractContent;
}

function* getSingleContractTransactions() {
  try {
    const contractContent: ContractContentSchema = yield fetchContractContent({ page: 0 });

    yield all([
      put(actions.setPaginationPage(0)),
      put(
        actions.setSingleContractTransactions({
          value: contractContent.records,
          hasData: Boolean(contractContent.records.length),
          isAllDataReceived: contractContent.records.length < LIMIT,
        })
      ),
      put(
        actions.setCheckedItems(
          contractContent.records.reduce<ICheckedItems>((checkedItems, currentItem) => {
            if (currentItem.document_id && currentItem.status === FileStatusEnum.DIGITIZED) {
              return {
                ...checkedItems,
                [currentItem.id]: {
                  documentId: currentItem.document_id,
                  checked: false,
                },
              };
            }

            return checkedItems;
          }, {})
        )
      ),
    ]);
  } catch (e) {
    yield call(responseError, e);
    yield put(
      actions.setSingleContractTransactions({
        error: e as Error,
        hasData: false,
        isAllDataReceived: false,
      })
    );
  }
}

function* setChunkSingleContractTransactions() {
  try {
    const page: number = yield select(getPaginationPage);
    const newContractContent: ContractContentSchema = yield fetchContractContent({ page: page + 1 });

    yield all([
      put(actions.setPaginationPage(page + 1)),
      put(
        actions.setChunkSingleContractTransactions({
          value: newContractContent.records,
          isAllDataReceived: newContractContent.records.length < LIMIT,
        })
      ),
    ]);

    const items: InboxRecordSchema[] = yield select(getSingleContractTransactionsItems);

    if (items) {
      yield put(
        actions.setCheckedItems(
          items.reduce<ICheckedItems>((checkedItems, currentItem) => {
            if (currentItem.document_id && currentItem.status === FileStatusEnum.DIGITIZED) {
              return {
                ...checkedItems,
                [currentItem.id]: {
                  documentId: currentItem.document_id,
                  checked: false,
                },
              };
            }

            return checkedItems;
          }, {})
        )
      );
    }
  } catch (e) {
    yield call(responseError, e);
  }
}

export default [
  takeLatest(actions.getSingleContractTransactions, getSingleContractTransactions),
  takeLatest(actions.setChunkSingleContractTransactions, setChunkSingleContractTransactions),
];
