import { HeaderCell, TableRow } from '@core-ui/table';
import { SORT_ORDER_ENUM } from '@core-ui/types';
import Box from '@mui/material/Box';
import { SxProps, Theme } from '@mui/material/styles';
import TableHead from '@mui/material/TableHead';
import { useAppSelector } from 'src/hooks/useAppSelector';
import { FILTERS_HEIGHT } from 'src/pages/backoffice/Inbox/consts';
import { getInboxList, setOrder, setOrderBy } from 'src/pages/backoffice/Inbox/InboxList/actions';
import { getSortAndOrder } from 'src/pages/backoffice/Inbox/InboxList/selector';
import React from 'react';
import { batch, useDispatch } from 'react-redux';
import { HEADERS } from './consts';

const headStyles: SxProps<Theme> = { height: 38 };

const TableHeader = () => {
  const { orderBy, order } = useAppSelector(getSortAndOrder);

  const dispatch = useDispatch();

  const handleChangeSort = (value?: string) => () => {
    if (!value) {
      return;
    }

    batch(() => {
      if (orderBy !== value) {
        dispatch(setOrder(SORT_ORDER_ENUM.DESC));
      } else {
        dispatch(setOrder(order === SORT_ORDER_ENUM.ASC ? SORT_ORDER_ENUM.DESC : SORT_ORDER_ENUM.ASC));
      }

      dispatch(setOrderBy(value));
      dispatch(getInboxList(null));
    });
  };

  return (
    <TableHead sx={headStyles}>
      <TableRow>
        {HEADERS.map((column) => (
          <HeaderCell
            key={column.key}
            align={column.align}
            right={column.right}
            shadow={column.shadow}
            sticky
            isActive={orderBy === column.orderBy}
            onChangeSort={column.orderBy ? handleChangeSort(column.orderBy) : undefined}
            order={order}
            sx={{
              top: FILTERS_HEIGHT,
              minWidth: column.width,
              width: column.width,
              maxWidth: column.width,
            }}
          >
            <Box>{column.title}</Box>
          </HeaderCell>
        ))}
      </TableRow>
    </TableHead>
  );
};

export default TableHeader;
