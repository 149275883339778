import Grid from '@mui/material/Grid';
import { SxProps, Theme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import { DATE_FORMAT } from 'src/app/consts/app';
import { formatTime } from 'src/app/utils/dates';
import NoDataContainer from 'src/components/NoDataContainer';
import { useAppSelector } from 'src/hooks/useAppSelector';
import { INFO_TAB_LABEL_WIDTH, INFO_TAB_VALUE_FIELD_WIDTH } from 'src/pages/backoffice/Contracts/consts';
import { getNoDataContainerData } from 'src/pages/backoffice/Contracts/SingleContractRoot/selector';
import React, { FC } from 'react';
import { FormattedMessage } from 'react-intl';
import { SingleContractInfoSkeleton } from '../skeletons';
import selector from './selector';

const containerStyles: SxProps<Theme> = { overflow: 'scroll' };

const SingleContractInfo: FC = () => {
  const { item, amountFormatter, getCurrencyCode } = useAppSelector(selector);

  const issueDate = item?.created_at ? (
    formatTime(item.created_at, { dateFormat: DATE_FORMAT })
  ) : (
    <FormattedMessage id="label.no_info" />
  );

  const expireDate = item?.expires_at ? (
    formatTime(item.expires_at, { dateFormat: DATE_FORMAT })
  ) : (
    <FormattedMessage id="label.no_info" />
  );

  const description = item?.description ?? <FormattedMessage id="label.no_info" />;
  const vendorName = item?.vendor_name ?? <FormattedMessage id="label.no_info" />;
  const managerName = item?.manager_info?.name ?? <FormattedMessage id="label.no_info" />;
  const managerPhone = item?.manager_info?.phone ?? <FormattedMessage id="label.no_info" />;
  const managerEmail = item?.manager_info?.email ?? <FormattedMessage id="label.no_info" />;

  return (
    <NoDataContainer sx={containerStyles} selector={getNoDataContainerData} loader={<SingleContractInfoSkeleton />}>
      <Grid container direction="column" gap={4} paddingBottom={16}>
        <Grid item container>
          <Grid item width={INFO_TAB_LABEL_WIDTH}>
            <Typography color="text.secondary">
              <FormattedMessage id="label.date_of_issue" />
            </Typography>
          </Grid>

          <Grid item width={INFO_TAB_VALUE_FIELD_WIDTH}>
            {issueDate}
          </Grid>
        </Grid>

        <Grid item container>
          <Grid item width={INFO_TAB_LABEL_WIDTH}>
            <Typography color="text.secondary">
              <FormattedMessage id="label.expire_date" />
            </Typography>
          </Grid>

          <Grid item width={INFO_TAB_VALUE_FIELD_WIDTH}>
            {expireDate}
          </Grid>
        </Grid>

        <Grid item container>
          <Grid item width={INFO_TAB_LABEL_WIDTH}>
            <Typography color="text.secondary">
              <FormattedMessage id="label.currency" />
            </Typography>
          </Grid>

          <Grid item width={INFO_TAB_VALUE_FIELD_WIDTH}>
            {getCurrencyCode(item?.currency_id)}
          </Grid>
        </Grid>

        <Grid item container>
          <Grid item width={INFO_TAB_LABEL_WIDTH}>
            <Typography color="text.secondary">
              <FormattedMessage id="label.price" />
            </Typography>
          </Grid>

          <Grid item width={INFO_TAB_VALUE_FIELD_WIDTH}>
            <Typography>{amountFormatter(item?.amount)}</Typography>
          </Grid>
        </Grid>

        <Grid item container>
          <Grid item width={INFO_TAB_LABEL_WIDTH}>
            <Typography color="text.secondary">
              <FormattedMessage id="label.description" />
            </Typography>
          </Grid>

          <Grid item width={INFO_TAB_VALUE_FIELD_WIDTH}>
            {description}
          </Grid>
        </Grid>

        <Grid item container>
          <Grid item width={INFO_TAB_LABEL_WIDTH}>
            <Typography color="text.secondary">
              <FormattedMessage id="label.vendor" />
            </Typography>
          </Grid>

          <Grid item width={INFO_TAB_VALUE_FIELD_WIDTH}>
            {vendorName}
          </Grid>
        </Grid>

        <Grid item container>
          <Grid item width={INFO_TAB_LABEL_WIDTH}>
            <Typography color="text.secondary">
              <FormattedMessage id="label.manager" />
            </Typography>
          </Grid>

          <Grid item width={INFO_TAB_VALUE_FIELD_WIDTH}>
            {managerName}
          </Grid>
        </Grid>

        <Grid item container>
          <Grid item width={INFO_TAB_LABEL_WIDTH}>
            <Typography color="text.secondary">
              <FormattedMessage id="label.contact" />
            </Typography>
          </Grid>

          <Grid item width={INFO_TAB_VALUE_FIELD_WIDTH}>
            {managerPhone}
          </Grid>
        </Grid>

        <Grid item container>
          <Grid item width={INFO_TAB_LABEL_WIDTH}>
            <Typography color="text.secondary">
              <FormattedMessage id="label.email" />
            </Typography>
          </Grid>

          <Grid item width={INFO_TAB_VALUE_FIELD_WIDTH}>
            {managerEmail}
          </Grid>
        </Grid>
      </Grid>
    </NoDataContainer>
  );
};

export default SingleContractInfo;
