import { createParseQuery, resetState, setFieldFilter } from '@core-ui/reducers_handlers';
import { createAction } from 'redux-actions';

export const reducerName = 'inbox.filters';

export const setTextFilter = setFieldFilter(reducerName);
export const resetInboxFilters = resetState(reducerName);

export const updateInboxFiltersQuery = createAction(`${reducerName}.UPDATE_INBOX_FILTERS_QUERY`);
export const setInboxFiltersFromQuery = createParseQuery(reducerName);
