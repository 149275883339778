import { vehicleIdSelector } from 'src/app/selectors';
import { getAmountFormatterWithCurrencyCodeById } from 'src/dictionary/selector';
import { getSingleContract } from 'src/pages/backoffice/Contracts/SingleContractRoot/selector';
import { createSelector } from 'reselect';

export default createSelector(
  [getSingleContract, vehicleIdSelector, getAmountFormatterWithCurrencyCodeById],
  (transaction, boatId, currencyFormatter) => ({
    item: transaction.value ?? null,
    boatId,
    currencyFormatter,
  })
);
