import { COLOURS } from '@core-ui/styles';
import { HeaderCell, TableRow } from '@core-ui/table';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import { SxProps, Theme } from '@mui/material/styles';
import SvgIcon from '@mui/material/SvgIcon';
import TableHead from '@mui/material/TableHead';
import { useAppSelector } from 'src/hooks/useAppSelector';
import { getSortAndOrder } from 'src/pages/backoffice/Contracts/ContractsList/selector';
import { ArrowDown, ArrowsDownUp, ArrowUp } from '@phosphor-icons/react';
import React, { FC } from 'react';
import { batch, useDispatch } from 'react-redux';
import * as actions from '../actions';
import { HEADERS } from './consts';

const headStyles: SxProps<Theme> = { height: 38 };

const TableHeader: FC = () => {
  const { orderBy, isSortDesc } = useAppSelector(getSortAndOrder);

  const dispatch = useDispatch();

  const handleChangeSort = (value?: string) => () => {
    if (!value) {
      return;
    }

    batch(() => {
      if (orderBy !== value) {
        dispatch(actions.setSortDesc(true));
      } else {
        dispatch(actions.setSortDesc(!isSortDesc));
      }

      dispatch(actions.setOrderBy(value));
      dispatch(actions.getContractsList(null));
    });
  };

  return (
    <TableHead sx={headStyles}>
      <TableRow>
        {HEADERS.map((column) => (
          <HeaderCell
            key={column.key}
            align={column.align}
            onClick={handleChangeSort(column.orderBy)}
            style={{
              cursor: column.orderBy ? 'pointer' : 'initial',
              minWidth: column.width,
              width: column.width,
              maxWidth: column.width,
            }}
          >
            <Grid
              container
              alignItems="center"
              gap={1}
              wrap="nowrap"
              justifyContent={
                column.align === 'right' ? 'flex-end' : column.align === 'center' ? 'center' : 'space-between'
              }
            >
              <Box>{column.title}</Box>

              {column.orderBy && (
                <SvgIcon fontSize="small">
                  {column.orderBy === orderBy && isSortDesc && <ArrowDown />}
                  {column.orderBy === orderBy && !isSortDesc && <ArrowUp />}
                  {column.orderBy !== orderBy && <ArrowsDownUp color={COLOURS.HUE_COCONUT.HUE_100} />}
                </SvgIcon>
              )}
            </Grid>
          </HeaderCell>
        ))}
      </TableRow>
    </TableHead>
  );
};

export default TableHeader;
