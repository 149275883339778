import Loader from '@core-ui/loader';
import { COLOURS } from '@core-ui/styles';
import Grid from '@mui/material/Grid';
import SvgIcon from '@mui/material/SvgIcon';
import Typography from '@mui/material/Typography';
import LoadFileDropzone from 'src/components/LoadFileDropzone';
import { useAppSelector } from 'src/hooks/useAppSelector';
import { FileArrowUp } from '@phosphor-icons/react';
import React, { FC } from 'react';
import { FormattedMessage } from 'react-intl';
import { useDispatch } from 'react-redux';
import { uploadDocument } from './actions';
import { getLoading } from './selector';
import useStyles from './styles';

const SingleContractTransactionsDragNDrop: FC = () => {
  const loading = useAppSelector(getLoading);
  const styles = useStyles();
  const dispatch = useDispatch();

  const handleOnDrop = (files: File[]) => {
    dispatch(uploadDocument({ files }));
  };

  return (
    <Grid item container direction="column">
      <Grid item>
        <LoadFileDropzone
          reducerName="test_reducer"
          handleOnDrop={handleOnDrop}
          render={(props, { getRootProps, getInputProps }) => (
            <Grid
              container
              alignItems="center"
              justifyContent="center"
              flexGrow={1}
              sx={styles.dropzone}
              {...getRootProps()}
            >
              <input {...getInputProps()} />
              <Grid item container direction="column" alignItems="center">
                {loading ? (
                  <Loader mode="circle" size={40} padding={2} />
                ) : (
                  <>
                    <SvgIcon fontSize="large">
                      <FileArrowUp />
                    </SvgIcon>

                    <Typography variant="body1" marginTop={1}>
                      <FormattedMessage id="label.drag_your_document" />
                    </Typography>

                    <Typography variant="body1" marginTop={1} color={COLOURS.HUE_FIG.HUE_100}>
                      <FormattedMessage id="label.or_click_to_browse" />
                    </Typography>
                  </>
                )}
              </Grid>
            </Grid>
          )}
        />
      </Grid>
    </Grid>
  );
};

export default SingleContractTransactionsDragNDrop;
