import Button from '@core-ui/button';
import { BREAKPOINTS } from '@core-ui/styles';
import { buildURL } from '@core-ui/url';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { ROUTES } from 'src/app/consts/routes';
import NoDataContainer from 'src/components/NoDataContainer';
import history from 'src/history';
import { useAppSelector } from 'src/hooks/useAppSelector';
import { BACKOFFICE_TABS } from 'src/pages/backoffice/consts';
import SingleContractHeaderMoreButton from 'src/pages/backoffice/Contracts/SingleContractHeader/SingleContractHeaderMoreButton';
import SingleContractTabs from 'src/pages/backoffice/Contracts/SingleContractHeader/SingleContractTabs';
import { getNoDataContainerData } from 'src/pages/backoffice/Contracts/SingleContractRoot/selector';
import { SingleContractHeaderSkeleton } from 'src/pages/backoffice/Contracts/skeletons';
import { ArrowLeft } from '@phosphor-icons/react';
import isNil from 'lodash-es/isNil';
import React, { FC } from 'react';
import { FormattedMessage } from 'react-intl';
import selector from './selector';

const SingleContractHeader: FC = () => {
  const { item, boatId, currencyFormatter } = useAppSelector(selector);

  const handleBackToContracts = () => {
    history.push(buildURL(`/${boatId}/${ROUTES.BACKOFFICE}/${BACKOFFICE_TABS.CONTRACTS}`));
  };

  const title = item?.name ?? <FormattedMessage id="label.unknown_name" />;

  const transactionsCount = !isNil(item?.transactions_number) ? (
    <FormattedMessage id="label.transactions_count" values={{ count: item?.transactions_number ?? 0 }} />
  ) : (
    <FormattedMessage id="label.unknown_amount" />
  );

  return (
    <NoDataContainer selector={getNoDataContainerData} loader={<SingleContractHeaderSkeleton />} direction="column">
      <Grid item container direction="column" padding="24px 0">
        <Grid item container marginBottom={2}>
          <Button
            variant="text"
            color="blue"
            compact
            label={<FormattedMessage id="label.back_to_contracts" />}
            startIcon={<ArrowLeft />}
            onClick={handleBackToContracts}
          />
        </Grid>

        <Grid item container marginBottom={3} marginRight={4} gap={4}>
          <Typography variant="h3">
            {title}
          </Typography>

          <Grid item>
            <SingleContractHeaderMoreButton item={item} />
          </Grid>
        </Grid>

        <Grid item>
          <Typography fontWeight="semiBold">
            {currencyFormatter(item?.currency_id, item?.transactions_total_price)}
          </Typography>
        </Grid>

        <Grid item marginBottom={7}>
          <Typography color="text.secondary">{transactionsCount}</Typography>
        </Grid>

        <Grid item width="100%" maxWidth={BREAKPOINTS.XL}>
          <SingleContractTabs />
        </Grid>
      </Grid>
    </NoDataContainer>
  );
};

export default SingleContractHeader;
