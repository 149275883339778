import { HEIGHT_SIZE } from '@core-ui/styles';
import { containerClasses } from '@mui/material';
import { SxProps, Theme } from '@mui/material/styles';

export default (): Record<string, SxProps<Theme>> => ({
  container: {
    [`&.${containerClasses.root}`]: {
      padding: 0,
    },
  },

  innerContainer: {
    position: 'relative',
    height: `calc(100vh - (${HEIGHT_SIZE.headerHeight + HEIGHT_SIZE.tabsHeight}px))`,
    overflow: 'auto',
    justifyContent: 'flex-start',
    flexDirection: 'column',
    flexWrap: 'nowrap',
  },
});
