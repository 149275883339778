import { getParams } from '@core-ui/redux-router';
import { GET } from 'src/api/oceanApi';
import { responseError } from 'src/app/sagas';
import { ISagaContext } from 'src/app/types/common';
import { ContractOutputSchema } from 'src/generated';
import { CONTRACTS_PATHS } from 'src/pages/backoffice/Contracts/routes';
import { SingleContractPathParams } from 'src/pages/backoffice/Contracts/types';
import { call, getContext, put, takeLatest } from 'redux-saga/effects';
import * as actions from './actions';

function* getSingleContract() {
  const history: ISagaContext['history'] = yield getContext('history');

  try {
    const params = getParams<SingleContractPathParams>(history.location.pathname, CONTRACTS_PATHS);

    const contractData: ContractOutputSchema = yield call(GET, `/contracts/${params.contractId}`);

    if (!contractData) {
      throw new Error('No contract found');
    }

    yield put(
      actions.setSingleContract({
        value: contractData,
        hasData: true,
      })
    );
  } catch (e) {
    yield call(responseError, e);
    yield put(
      actions.setSingleContract({
        error: e as Error,
        hasData: false,
      })
    );
  }
}

export default [takeLatest(actions.getSingleContract, getSingleContract)];
