import { createParseQuery, resetState, setFieldFilter } from '@core-ui/reducers_handlers';
import { createAction } from 'redux-actions';

export const reducerName = 'contracts.filters';

export const setTextFilter = setFieldFilter(reducerName);
export const resetContractsFilters = resetState(reducerName);

export const updateContractsFiltersQuery = createAction(`${reducerName}.UPDATE_CONTRACTS_FILTERS_QUERY`);
export const setContractsFiltersFromQuery = createParseQuery(reducerName);
