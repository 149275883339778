import State from 'src/app/types/state';
import { NoDataContainerSelector } from 'src/components/NoDataContainer/NoDataContainer';
import { getAmountFormatter, getCurrencyCodeById } from 'src/dictionary/selector';
import { getInboxFilters } from 'src/pages/backoffice/Inbox/InboxFilters/selector';
import { createSelector } from 'reselect';

const getChunkLoading = (state: State) => state.backoffice.inbox.list.chunkLoading;
const getAllDataReceived = (state: State) => state.backoffice.inbox.list.isAllDataReceived;

export const chunkLoaderSelector = createSelector(
  [getChunkLoading, getAllDataReceived],
  (isChunkLoading, isAllDataReceived) => ({
    isChunkLoading,
    isAllDataReceived,
  })
);

const getInboxListState = (state: State) => state.backoffice.inbox.list;

export const getNoDataContainerData: NoDataContainerSelector = createSelector([getInboxListState], (state) => ({
  loading: state.loading,
  loaded: state.loaded,
  hasData: state.hasData,
  error: state.error,
}));

const getOrderBy = (state: State) => state.backoffice.inbox.list.orderBy;
const getOrder = (state: State) => state.backoffice.inbox.list.order;

export const getSortAndOrder = createSelector([getOrderBy, getOrder], (orderBy, order) => ({
  orderBy,
  order,
}));

export const getPaginationPage = (state: State) => state.backoffice.inbox.list.paginationPage;

const getPlaceholders = (state: State) => state.backoffice.inbox.dragNDrop.placeholders;

const getFiltersApplied = createSelector([getInboxFilters], (filters) =>
  Object.values(filters).some((value) => {
    if (Array.isArray(value)) {
      return value.length > 0;
    }

    return Boolean(value);
  })
);

export default createSelector(
  [getPlaceholders, getInboxListState, getFiltersApplied, getAmountFormatter, getCurrencyCodeById],
  (placeholders, inboxList, isFiltersApplied, amountFormatter, currencyCodeGetter) => ({
    placeholders,
    items: inboxList.value,
    isFiltersApplied,
    amountFormatter,
    currencyCodeGetter,
  })
);
