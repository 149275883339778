import Tooltip from '@mui/material/Tooltip';
import SvgIcon from '@mui/material/SvgIcon';
import { OrderContractsFields } from 'src/generated';
import { ITableColumn } from 'src/pages/backoffice/types';
import { File } from '@phosphor-icons/react';
import React from 'react';
import { FormattedMessage } from 'react-intl';

export const HEADERS: ITableColumn[] = [
  {
    key: 'contract_name',
    title: <FormattedMessage id="table.header.contract_name" />,
    orderBy: OrderContractsFields.NAME,
    dataTestId: 'table-column-head-name',
    width: 372,
  },
  {
    key: 'vendor',
    title: <FormattedMessage id="table.header.vendor" />,
    orderBy: OrderContractsFields.VENDOR_ID,
    dataTestId: 'table-column-head-vendor',
    width: 372,
  },
  {
    key: 'expire_date',
    title: <FormattedMessage id="table.header.expire_date" />,
    orderBy: OrderContractsFields.EXPIRES_AT,
    dataTestId: 'table-column-head-expire-date',
    width: 174,
  },
  {
    key: 'amount_of_transactions_icon',
    title: (
      <Tooltip title={<FormattedMessage id="tooltip.amount_of_transactions" />} placement="top" arrow>
        <SvgIcon>
          <File />
        </SvgIcon>
      </Tooltip>
    ),
    width: 46,
    align: 'center',
  },
  {
    key: 'currency',
    title: (
      <Tooltip title={<FormattedMessage id="table.header.currency" />} placement="top" arrow>
        <div>
          <FormattedMessage id="table.header.currency_short" />
        </div>
      </Tooltip>
    ),
    width: 54,
  },
  {
    key: 'expenses',
    title: <FormattedMessage id="table.header.expenses" />,
    orderBy: OrderContractsFields.AMOUNT,
    dataTestId: 'table-column-head-expenses',
    width: 210,
    align: 'right',
  },
  {
    key: 'actions',
    title: '',
    width: 108,
  },
];
