import { ScrollMemory } from '@core-ui/redux-router';
import Grid from '@mui/material/Grid';
import { SxProps, Theme } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import ChunkLoader from 'src/components/ChunkLoader';
import NoDataContainer from 'src/components/NoDataContainer';
import NoDataPlaceholder from 'src/components/NoDataPlaceholder';
import NoFiltratedDataPlaceholder from 'src/components/NoFiltratedDataPlaceholder';
import TableSkeleton from 'src/components/TableSkeleton';
import { useAppSelector } from 'src/hooks/useAppSelector';
import {
  resetSingleContractTransactionsFilters,
  updateSingleContractTransactionsFiltersQuery,
} from 'src/pages/backoffice/Contracts/SingleContractTransactions/SingleContractTransactionsFilters/actions';
import React, { FC } from 'react';
import { batch, useDispatch } from 'react-redux';
import { getChunkSingleContractTransactions, getSingleContractTransactions } from './actions';
import selector, { chunkLoaderSelector, getNoDataContainerData } from './selector';
import SingleContractPlaceholderRow from './SingleContractPlaceholderRow';
import SingleContractRow from './SingleContractRow';
import TableHeader from './TableHeaders';

const CONTAINER_ID = 'single-contract-transactions-list';

const noDataContainerStyle: SxProps<Theme> = {
  height: '100%',
  overflow: 'scroll',
};

const SingleContractTransactionsList: FC = () => {
  const { placeholders, items, amountFormatter, isFiltersApplied } = useAppSelector(selector);
  const dispatch = useDispatch();

  const handleClearFilters = () => {
    batch(() => {
      dispatch(resetSingleContractTransactionsFilters());
      dispatch(updateSingleContractTransactionsFiltersQuery());
      dispatch(getSingleContractTransactions(null));
    });
  };

  const noDataContent = isFiltersApplied ? (
    <NoFiltratedDataPlaceholder clearFilters={handleClearFilters} styles={{ marginTop: 10 }} />
  ) : (
    <NoDataPlaceholder />
  );

  return (
    <>
      <ScrollMemory elementID={CONTAINER_ID} />

      <NoDataContainer
        selector={getNoDataContainerData}
        sx={noDataContainerStyle}
        loader={<TableSkeleton rows={8} />}
        noDataContent={noDataContent}
        id={CONTAINER_ID}
      >
        <Grid item height="100%" width="100%">
          <Table stickyHeader>
            <TableHeader />

            <TableBody>
              {placeholders.map((placeholder) => (
                <SingleContractPlaceholderRow key={placeholder} item={placeholder} />
              ))}

              {items?.map((item) => (
                <SingleContractRow key={item.id} item={item} amountFormatter={amountFormatter} />
              ))}
            </TableBody>
          </Table>

          <ChunkLoader selector={chunkLoaderSelector} getChunk={getChunkSingleContractTransactions} />
        </Grid>
      </NoDataContainer>
    </>
  );
};

export default SingleContractTransactionsList;
