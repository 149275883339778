import { HEIGHT_SIZE } from '@core-ui/styles';
import Container from '@mui/material/Container';
import { SxProps, Theme } from '@mui/material/styles';
import ContractsFilters from 'src/pages/backoffice/Contracts/ContractsFilters';
import ContractsList from 'src/pages/backoffice/Contracts/ContractsList';
import React from 'react';

const containerStyle: SxProps<Theme> = {
  padding: '0 24px !important',
  height: `calc(100vh - (${HEIGHT_SIZE.headerHeight + HEIGHT_SIZE.tabsHeight}px))`,
  overflow: 'hidden',
};

const ContractsRoot = () => {
  return (
    <Container maxWidth="xl" sx={containerStyle}>
      <ContractsFilters />

      <ContractsList />
    </Container>
  );
};

export default ContractsRoot;
