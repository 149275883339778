import { SxProps, Theme } from '@mui/material/styles';

export default (): Record<string, SxProps<Theme>> => ({
  head: {
    height: 38,
  },
  checkboxCell: {
    minWidth: 44,
    width: 44,
    maxWidth: 44,
    padding: 0,
    textAlign: 'center',
  },
});
