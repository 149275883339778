import State from 'src/app/types/state';
import { toMutableArray } from 'src/app/utils/app';
import { getUploadedByOptions } from 'src/dictionary/selector';
import { ISingleContractListFiltersState } from 'src/pages/backoffice/Contracts/SingleContractTransactions/SingleContractTransactionsFilters/reducer';
import { createSelector } from 'reselect';

export const keys: Array<keyof ISingleContractListFiltersState> = [
  'search',
  'dateFrom',
  'dateTo',
  'uploadedBy',
  'status',
];

export const getSingleContractTransactionsFilters = (state: State) =>
  state.backoffice.contracts.singleContractTransactions.filters;

export default createSelector(
  [getSingleContractTransactionsFilters, getUploadedByOptions],
  (filters, uploadedByOptions) => ({
    filters: {
      ...filters,

      uploadedBy: toMutableArray(filters.uploadedBy),
      status: toMutableArray(filters.status),
    },
    uploadedByOptions,
  })
);
