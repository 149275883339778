import reducer, { Data } from '@core-ui/reducers_handlers';
import { ContractOutputSchema } from 'src/generated';
import { handleActions } from 'redux-actions';
import Immutable from '@core-ui/immutable';
import * as actions from './actions';

export interface ISingleContractState extends Data<ContractOutputSchema> {}

const defaultState = Immutable<ISingleContractState>({
  loaded: false,
  hasData: false,
  loading: false,
});

export default handleActions<typeof defaultState, unknown>(
  {},
  reducer<typeof defaultState>(actions.reducerName, defaultState)
);
