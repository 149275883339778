import State from 'src/app/types/state';
import { toMutableArray } from 'src/app/utils/app';
import { getVendorsDictOptions } from 'src/dictionary/selector';
import { IContractsFiltersState } from 'src/pages/backoffice/Contracts/ContractsFilters/reducer';
import { createSelector } from 'reselect';

export const keys: Array<keyof IContractsFiltersState> = ['name', 'vendorsId'];

export const getContractsFilters = (state: State) => state.backoffice.contracts.filters;

export default createSelector([getContractsFilters, getVendorsDictOptions], (filters, vendorsOptions) => ({
  filters: {
    ...filters,

    vendorsId: toMutableArray(filters.vendorsId),
  },
  vendorsOptions,
}));
