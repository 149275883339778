import { FileStatusEnum } from 'src/generated';
import capitalize from 'lodash-es/capitalize';

export enum SINGLE_CONTRACT_TAB_ENUM {
  INFORMATION = 'information',
  TRANSACTIONS = 'transactions',
  SOURCE_FILE = 'source_file',
}

export const INFO_TAB_LABEL_WIDTH = 230;

export const INFO_TAB_VALUE_FIELD_WIDTH = 450;

export const TRANSACTIONS_STATUS_OPTIONS = Object.values(FileStatusEnum).map((option) => ({
  label: capitalize(option.replace(/_/g, ' ')),
  value: option,
}));
