import { extractFlattenPaths, Route } from '@core-ui/redux-router';
import { ROUTES } from 'src/app/consts/routes';
import { getClientCostArticleDict, getVendorDict } from 'src/dictionary/actions';
import { BACKOFFICE_TABS } from 'src/pages/backoffice/consts';
import {
  resetContractsFilters,
  setContractsFiltersFromQuery,
  updateContractsFiltersQuery,
} from 'src/pages/backoffice/Contracts/ContractsFilters/actions';
import { getContractsList, resetContractsList } from 'src/pages/backoffice/Contracts/ContractsList/actions';
import ContractsRoot from 'src/pages/backoffice/Contracts/ContractsRoot';
import SingleContractRoot from 'src/pages/backoffice/Contracts/SingleContractRoot';
import { getSingleContract, resetSingleContract } from 'src/pages/backoffice/Contracts/SingleContractRoot/actions';
import { IBackofficeState } from 'src/pages/backoffice/reducer';

const key: keyof IBackofficeState = 'contracts';

const routes: Route[] = [
  {
    key,
    exact: true,
    path: `/:boatId([0-9]+)/:route(${ROUTES.BACKOFFICE})/:backofficeTab(${BACKOFFICE_TABS.CONTRACTS})`,
    component: ContractsRoot,
    effects: [
      { events: ['on_enter'], trigger: resetContractsList },
      { events: ['on_enter'], trigger: resetContractsFilters },
      { events: ['on_enter'], trigger: setContractsFiltersFromQuery },
      { events: ['on_enter'], trigger: updateContractsFiltersQuery },
      { events: ['on_enter'], trigger: getContractsList },
      { events: ['once'], trigger: getVendorDict },
    ],
  },
  {
    key,
    exact: true,
    path: `/:boatId([0-9]+)/:route(${ROUTES.BACKOFFICE})/:backofficeTab(${BACKOFFICE_TABS.CONTRACTS})/:contractId`,
    component: SingleContractRoot,
    effects: [
      // transaction tab effects are dispatched in SingleContractTransactions component via useEffect
      { events: ['on_enter'], trigger: resetSingleContract },
      { events: ['on_enter'], trigger: getSingleContract },
      { events: ['once'], trigger: getVendorDict },
      { events: ['once'], trigger: getClientCostArticleDict },
    ],
  },
];

export const CONTRACTS_PATHS = extractFlattenPaths(routes);

export default routes;
