import Grid from '@mui/material/Grid';
import { SxProps, Theme } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import ChunkLoader from 'src/components/ChunkLoader';
import NoDataContainer from 'src/components/NoDataContainer';
import NoDataPlaceholder from 'src/components/NoDataPlaceholder';
import NoFiltratedDataPlaceholder from 'src/components/NoFiltratedDataPlaceholder';
import TableSkeleton from 'src/components/TableSkeleton';
import { useAppSelector } from 'src/hooks/useAppSelector';
import { resetInboxFilters, updateInboxFiltersQuery } from 'src/pages/backoffice/Inbox/InboxFilters/actions';
import { getChunkInboxList, getInboxList } from 'src/pages/backoffice/Inbox/InboxList/actions';
import InboxPlaceholderRow from 'src/pages/backoffice/Inbox/InboxList/InboxPlaceholderRow';
import InboxRow from 'src/pages/backoffice/Inbox/InboxList/InboxRow';
import React, { FC } from 'react';
import { batch, useDispatch } from 'react-redux';
import selector, { chunkLoaderSelector, getNoDataContainerData } from './selector';
import TableHeader from './TableHeaders';

const containerStyles: SxProps<Theme> = {
  paddingX: 6,
};

const InboxList: FC = () => {
  const { placeholders, items, isFiltersApplied, amountFormatter, currencyCodeGetter } = useAppSelector(selector);
  const dispatch = useDispatch();

  const handleClearFilters = () => {
    batch(() => {
      dispatch(resetInboxFilters());
      dispatch(updateInboxFiltersQuery());
      dispatch(getInboxList(null));
    });
  };

  const noDataContent = isFiltersApplied ? (
    <NoFiltratedDataPlaceholder clearFilters={handleClearFilters} />
  ) : (
    <NoDataPlaceholder />
  );

  return (
    <NoDataContainer
      selector={getNoDataContainerData}
      loader={<TableSkeleton rows={10} />}
      noDataContent={noDataContent}
      sx={containerStyles}
    >
      <Grid item width="100%" height="100%">
        <Table stickyHeader>
          <TableHeader />

          <TableBody>
            {placeholders.map((placeholder) => (
              <InboxPlaceholderRow key={placeholder} item={placeholder} />
            ))}

            {items?.map((item) => (
              <InboxRow
                key={item.id}
                item={item}
                amountFormatter={amountFormatter}
                currencyCodeGetter={currencyCodeGetter}
              />
            ))}
          </TableBody>
        </Table>
      </Grid>

      <ChunkLoader selector={chunkLoaderSelector} getChunk={getChunkInboxList} />
    </NoDataContainer>
  );
};

export default InboxList;
