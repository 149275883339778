import { getChunkList, getList, resetState, setChunkList, setList, setLoading } from '@core-ui/reducers_handlers';
import { ContractOutputSchema } from 'src/generated';
import { createAction } from 'redux-actions';

export const reducerName = 'contracts.list';

export const getContractsList = getList(reducerName);
export const setContractsList = setList<ContractOutputSchema>(reducerName);

export const getChunkContractsList = getChunkList(reducerName);
export const setChunkContractsList = setChunkList<ContractOutputSchema>(reducerName);

export interface IDeleteContract {
  id: number;
  shouldRedirect: boolean;
}

export const deleteContract = createAction<IDeleteContract>(`${reducerName}.DELETE_CONTRACT`);

export const setOrderBy = createAction<string>(`${reducerName}.SET_ORDER_BY`);
export const setSortDesc = createAction<boolean>(`${reducerName}.SET_SORT_DESC`);

export const setPaginationPage = createAction<number>(`${reducerName}.SET_PAGINATION_PAGE`);

export const setContractListLoading = setLoading(reducerName);

export const resetContractsList = resetState(reducerName);
