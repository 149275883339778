import { HEIGHT_SIZE } from '@core-ui/styles';
import { SxProps, Theme } from '@mui/material/styles';

export default (): Record<string, SxProps<Theme>> => ({
  paper: {
    display: 'flex',
    justifyContent: 'center',
    height: HEIGHT_SIZE.tabsHeight,
    position: 'fixed',
    width: '100%',
    zIndex: 3,
  },
});
